<template>
  <div class="addressList">
    <ul>
      <li v-for="(item, index) of addressList" :key="index" @click="setDefaultAddress(item)">
        <div class="address-box">
          <div class="address-contant">
            <div class="address-info">
              <span>{{item.name}}</span>
              <span class="phone">{{item.phone}}</span>
              <!-- <input type="radio" value="1"> -->
            </div>
            <div class="address-info">
              <span class="info">
                {{item.fullName}}
              </span>
            </div>
          </div>
          <div class="check" v-if="defaultAddress">
            <img v-if="defaultAddress.id !== item.id" src="@/assets/images/h5-icon/icon-1.png">
            <img v-if="defaultAddress.id === item.id" src="@/assets/images/h5-icon/icon-2.png">
          </div>
        </div>
        
        <div class="operatBox">
          <div class="btn-box" @click.stop="editAddress(item)">
            <img src="@/assets/images/h5-icon/edit.png">
            <span>修改</span>
          </div>
          <div class="btn-box" @click.stop="deleteAddress(item.id)">
            <img src="@/assets/images/h5-icon/delete.png">
            <span>删除</span>
          </div>
        </div>
      </li>
    </ul>
    <div class="addAddress" @click="editAddress">
      <p class="addBtn"><van-icon size="20" name="plus" /> 新增地址</p>
    </div>
  </div>
</template>
<script>
import { getAddressList, deleteAddress, setDefaultAddress, getDefaultAddressList } from '@/api/api'

export default {
  name:"addressManage",
  data() {
    return {
      addressList: [],
      defaultAddress: null,
    }
  },
  mounted() {
    this.getAddressList();
    this.getDefaultAddressList()
    window.localStorage.removeItem('editAddress')
  },
  methods:{
    async getDefaultAddressList() {
      let response = await getDefaultAddressList()
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.defaultAddress = response.data || null
    },
    async setDefaultAddress(item) {
      if(!item) {
        return false
      }
      let params = {
        id: item.id
      }
      this.defaultAddress = item
      let response = await setDefaultAddress(params)
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.$toast(response.returnMsg)
      // this.$toast(response.returnMsg)
      await this.getAddressList()
      await this.getDefaultAddressList()
      window.history.go(-1)
    },
    async deleteAddress(id) {
      let params = {
        id: id
      }
      let response = await deleteAddress(params)
      if(+response.returnCode !== 10001) {
         this.$toast(response.returnMsg)
        return false
      }
       this.$toast(response.returnMsg)
      this.getAddressList()
    },
    async getAddressList() {
      let response = await getAddressList()
      if(+response.returnCode !== 10001) {
        this.$toast(response.returnMsg)
        return false
      }
      this.addressList = response.data
    },
    toAdd(){
      this.$router.push({
        path: '/addAddress',
      })
    },
    
    editAddress(item = null){
      if(item) {
        window.localStorage.setItem('editAddress',JSON.stringify(item))
      }
      this.$router.push({path: '/course/signup/editAddress'})
    },
  }
  
}
</script>
<style lang="less" scoped>
.addressList{
  width: 100%;
  background:#F3F3F3;
  height: 100%;
  /* padding-bottom: 2rem; */
}
.addressList ul li{
  // width:94%;
  padding:0 3%;
  margin-bottom:0.25rem;
  background: #fff;
}
.addressList ul li .address-contant{
  padding:0.2rem 0;
  width: 100%;
}
.addressList ul li .address-contant .address-info{
  line-height: 0.5rem;
  padding:0.1rem 0;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items:center;
}
.addressList ul li .address-contant .address-info span{
  margin-right:0.26rem;
  color:#333;
}
.addressList ul li .address-contant .address-info .phone{
  flex-grow:2;
}
.addressList ul li .address-contant .address-info .info{
  color:#999;
}
.operatBox{
  display: flex;
  justify-content:flex-end;
  height:1rem;
  border-top:1px solid #f2f2f5;
}
.operatBox .btn-box{
  display:inline-flex;
  align-items: center;
  height:0.3rem;
  line-height: 0.3rem;
  padding:0.2rem 0;
  font-size: 12px;
  margin-left: 0.36rem;
}
.operatBox .btn-box img{
  width:0.24rem;
  margin-right: 0.1rem;
}
.addAddress{
  width:100%;
  height:1.8rem;
  background:#fff;
  position: fixed;
  bottom:0;
}
.addAddress .addBtn{
  width:94%;
  height:0.8rem;
  line-height: 0.8rem;
  background: #FF3F47;
  border-radius: 10px;
  color:#fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin:0.5rem 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addAddress .addBtn i{
  font-size: 30px;
  font-weight: normal;
  margin-right: 0.1rem;
}
.address-box{
  display: flex;
  .check{
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    padding-top: 0.3rem;
    img{
      width: 0.32rem;
      height: 0.32rem;
    }
  }
}
</style>